import React from 'react';
import BasicModal from '../BasicModal/BasicModal';
import { useDispatch, useSelector } from 'react-redux';
import { setAxiosApiErrorDetailsModalVisible } from '../../../redux/slices/globalSlice';
import { t } from '../../T';
import './AxiosErrorDetailsModal.css';
import infoIcon from '../../../assets/icons/statuses/status-failed.svg';

const AxiosErrorDetailsModal = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const modalDetails = useSelector((state) => state.globalReducer.axiosApiErrorDetailsModalVisible);

  return modalDetails?.open ? (
    <div className='axios-api-error-details-wrapper'>
      <BasicModal
        icon={infoIcon}
        hideSubmitButton={true}
        title={t(lng, 'app.api.error.details')}
        closeCallback={() => dispatch(setAxiosApiErrorDetailsModalVisible({ open: false }))}
      >
        <ul className='error-details'>
          {modalDetails?.details?.map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      </BasicModal>
    </div>
  ) : null;
};

export default AxiosErrorDetailsModal;
