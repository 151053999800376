import './ContactCreateSuccessModal.css';
import { ReactComponent as PendingDeviceImage } from '../../../../assets/images/pending-approval-device.svg';
import { T } from '../../../T';
import Blockchain from '../../../Blockchain/Blockchain';
import CopyButton from '../../../CopyButton/CopyButton';
import Button from '../../../Button/Button';
import VModal from '../../../Modal/VModal';
import { useSelector } from 'react-redux';

const ContactCreateSuccessModal = ({
  addressName,
  blockchain,
  address,
  closeCallback,
  addCallback,
  displayAddAnotherButton,
}) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);

  const handleAddAnotherContact = () => {
    closeCallback();
    addCallback();
  };

  return (
    <VModal classHandler='contact-success-modal-vmodal' toggleFunction={closeCallback}>
      <div className='contact-success-modal'>
        <PendingDeviceImage />
        <span className='contact-success-modal-header'>
          <T>app.submitted.for.approval</T>
        </span>
        <span className='contact-success-modal-sub-header'>
          <T>app.add.contact.info</T>
        </span>
        <div className='contact-success-modal-info-wrapper'>
          <div className='contact-success-modal-info'>
            <span className='contact-success-modal-sub-text'>
              <T>app.contact.name</T>
            </span>
            {addressName}
          </div>
          <div className='contact-success-modal-info'>
            <span className='contact-success-modal-sub-text'>
              <T>app.blockchain</T>
            </span>
            <Blockchain
              blockchain={protocols
                ?.find((protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase())
                ?.currency?.toLowerCase()}
              showText
              symbol={protocols
                ?.find((protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase())
                ?.currency?.toLowerCase()}
            />
          </div>
          <div className='contact-success-modal-info'>
            <span className='contact-success-modal-sub-text'>
              <T>app.address</T>
            </span>
            <div className='contact-success-modal-address-wrapper'>
              <span className='create-contact-success-summary-address'>{address}</span>
              <CopyButton element={address} />
            </div>
          </div>
        </div>
        <div className='contact-success-modal-buttons'>
          {displayAddAnotherButton && (
            <Button fullWidth size='md' variant='primary' onClick={handleAddAnotherContact}>
              <T>app.add.another.contact</T>
            </Button>
          )}
          <Button fullWidth size='md' variant='neutral' onClick={closeCallback}>
            <T>app.close</T>
          </Button>
        </div>
      </div>
    </VModal>
  );
};

export default ContactCreateSuccessModal;
