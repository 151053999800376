import { useSelector } from 'react-redux';
import '../CreateTransactionRequestNew.css';
import CurrencyInput from 'react-currency-input-field';
import { currency } from '../../../../helpers/currency';
import { T, t } from '../../../T';
import BigNumber from 'bignumber.js';
import Button from '../../../Button/Button';
import Filter from '../../../Filter/Filter';
import speedFastIcon from '../../../../assets/icons/speedometer-icon.svg';
import speedSlowIcon from '../../../../assets/icons/slow-speedo.svg';
import speedMediumIcon from '../../../../assets/icons/medium-speedo.svg';
import { ReactComponent as ContactIcon } from '../../../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as QuestionMark } from '../../../../assets/icons/question-mark-no-bg.svg';
import { ReactComponent as InternalIcon } from '../../../../assets/icons/internal-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';
import Alert from '../../../Alert/Alert';
import { blockchainDecimalLimits } from '../../../../helpers/constants';
import VTooltip from '../../../VTooltip/VTooltip';
import Blockchain from '../../../Blockchain/Blockchain';

const CreateTransactionRequestAmountStep = ({
  min,
  max,
  isUtxo,
  staticTrxFee,
  exchangeRate,
  strategyFees,
  selectedAsset,
  submitCallback,
  selectedAmount,
  selectedRecipient,
  handleChooseAmount,
  selectedFeePriority,
  handleRemoveRecipient,
  handleChooseFeePriority,
}) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const maxDecimals = blockchainDecimalLimits?.[selectedAsset?.[0]?.blockchain] || 8;

  const handleCurrencyInputChange = (value) => {
    let valueFormat = max;
    if (parseFloat(value) < max) {
      valueFormat = value;
    }
    handleChooseAmount(typeof value === 'undefined' ? 0 : valueFormat);
  };

  const handleUtxoCurrencyInputChange = (value, recipientAddress) => {
    handleChooseAmount(typeof value === 'undefined' ? 0 : value, recipientAddress);
  };

  return (
    <div className='create-trx-request-step-three-wrapper'>
      {!isUtxo ? (
        <>
          <div className='create-trx-modal-v-input-group create-trx-modal-amount'>
            <div className='create-trx-request-modal-select-component-wrapper'>
              <span className='create-trx-request-modal-select-component-label'>
                <T>app.amount</T>
              </span>
              <div className='create-trx-modal-amount-input-acc-based'>
                <CurrencyInput
                  min={min}
                  className='form-control'
                  placeholder={t(lng, 'app.please.enter.amount')}
                  decimalsLimit={maxDecimals}
                  allowDecimals
                  allowNegativeValue={false}
                  value={selectedAmount}
                  defaultValue={selectedAmount === '0' || !selectedAmount ? '' : selectedAmount}
                  intlConfig={{ locale: 'en-US' }}
                  onValueChange={(value) => handleCurrencyInputChange(value)}
                />
                <div className='create-trx-modal-icon-netwrok'>
                  <Blockchain
                    showText={false}
                    assetType={selectedAsset?.[0]?.type?.toUpperCase()}
                    blockchain={protocols
                      ?.find(
                        (protocol) =>
                          protocol?.blockchain?.toLowerCase() ===
                          selectedAsset?.[0]?.blockchain?.toLowerCase(),
                      )
                      ?.currency?.toLowerCase()}
                    symbol={selectedAsset?.[0]?.symbol}
                  />
                </div>
                <div className='create-trx-modal-fiat-currency'>
                  {currency(BigNumber(selectedAmount).multipliedBy(exchangeRate)) === '$NaN'
                    ? '$0.00'
                    : currency(BigNumber(selectedAmount).multipliedBy(exchangeRate))}
                </div>
                <div className='create-trx-modal-min-max'>
                  <div className='create-trx-modal-min' onClick={() => handleChooseAmount(min)}>
                    Min: ({min} {` ${selectedAsset?.[0]?.symbol}`})
                  </div>
                  <div className='create-trx-modal-max' onClick={() => handleChooseAmount(max)}>
                    Max: ({`${max} ${selectedAsset?.[0]?.symbol}`})
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!staticTrxFee && (
            <div className='create-trx-request-modal-select-component-wrapper'>
              <span className='create-trx-request-modal-select-component-label'>
                <T>app.fee.priority</T>
              </span>
              <Filter
                items={[
                  {
                    name: 'Slow',
                    id: 'slow',
                    desc: t(lng, 'app.fee.priority.slow.desc'),
                    fee: strategyFees?.slow?.feeValue,
                    value: 'slow',
                    icon: speedSlowIcon,
                  },
                  {
                    name: 'Standard',
                    id: 'standard',
                    desc: t(lng, 'app.fee.priority.standard.desc'),
                    fee: strategyFees?.standard?.feeValue,
                    value: 'standard',
                    icon: speedMediumIcon,
                  },
                  {
                    name: 'Fast',
                    id: 'fast',
                    desc: t(lng, 'app.fee.priority.fast.desc'),
                    fee: strategyFees?.fast?.feeValue,
                    value: 'fast',
                    icon: speedFastIcon,
                  },
                ]}
                selectedItems={selectedFeePriority}
                onChange={handleChooseFeePriority}
                fullWidth
                placeholder='app.fee.priority'
                disableFocusShadowEffect
                customLabelTemplate={(priority) => (
                  <div className='create-trx-request-priority-wrapper'>
                    <img className='create-trx-request-priority-icon' src={priority?.icon} alt='' />
                    <div className='create-trx-request-priority-data-wrapper'>
                      <span className='create-trx-request-priority-data-label'>{priority?.name}</span>
                      <span className='create-trx-request-priority-data-desc-label'>{priority?.desc}</span>
                      <span className='create-trx-request-priority-data-fee-label'>
                        ~ {strategyFees?.unit?.toLowerCase()} {priority?.fee}
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <span className='create-trx-request-modal-select-component-label'>
            <T>app.transfer.to</T>
          </span>
          <div className='create-trx-request-modal-amount-labels-wrapper'>
            <span className='create-trx-request-modal-balance-label'>
              <T>app.available.balance</T>:{' '}
              {Math.trunc(
                parseFloat(selectedAsset?.[0]?.assetData?.availableAmount) * Math.pow(10, maxDecimals),
              ) / Math.pow(10, maxDecimals)}
            </span>
            <div className='create-trx-request-modal-balance-label-wrapper'>
              <span className='create-trx-request-modal-balance-label'>
                <T>app.selected</T>:{' '}
                {selectedRecipient
                  ?.reduce((accumulator, recipient) => {
                    return accumulator + (parseFloat(recipient?.selectedAmount) || 0);
                  }, 0)
                  ?.toFixed(maxDecimals)}{' '}
                {selectedAsset?.[0]?.symbol}
                {` (${currency(
                  selectedRecipient?.reduce((accumulator, recipient) => {
                    return accumulator + (parseFloat(recipient?.selectedAmount) || 0);
                  }, 0) * exchangeRate,
                )})`}
              </span>
            </div>
          </div>
          {parseFloat(max) <
            selectedRecipient?.reduce((accumulator, recipient) => {
              return accumulator + (parseFloat(recipient?.selectedAmount) || 0);
            }, 0) && <Alert text={t(lng, 'app.selected.balance.bigger.than.available')} variant='error' />}
          <div className='create-trx-request-modal-utxo-amounts-container'>
            {selectedRecipient?.length > 0 &&
              selectedRecipient?.map((recipient) => (
                <div key={recipient?.address} className='create-trx-request-modal-utxo-amount-row'>
                  <div className='create-trx-request-modal-utxo-amount-row-recipient-wrapper'>
                    <div>
                      {recipient?.internal && (
                        <div className='create-trx-request-modal-internal-icon'>
                          <InternalIcon />
                        </div>
                      )}
                      {!recipient?.name && !recipient?.internal && (
                        <div className='create-trx-request-modal-internal-icon'>
                          <QuestionMark />
                        </div>
                      )}
                      {recipient?.name && !recipient?.internal && (
                        <div className='create-trx-request-modal-contact-icon'>
                          <ContactIcon />
                        </div>
                      )}
                    </div>
                    <div className='create-trx-request-modal-destination-receivers-utxo-blockchain-text-wrapper'>
                      <span
                        className='create-trx-request-modal-destination-receivers-utxo-blockchain-text'
                        data-tooltip-id={`utxo-address-name-amount-step-${recipient?.address}`}
                        data-tooltip-html={`${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(
                          0,
                          80,
                        )} <br /> ${(recipient?.name || t(lng, 'app.unknown.address'))?.slice(80, 100)}`}
                      >
                        {recipient?.name || <T>app.unknown.address</T>}
                      </span>
                      <VTooltip id={`utxo-address-name-amount-step-${recipient?.address}`} />
                      <span className='create-trx-request-modal-destination-receivers-utxo-blockchain-sub-text'>
                        {recipient?.address}
                      </span>
                    </div>
                  </div>
                  <div className='create-trx-modal-amount-input'>
                    <CurrencyInput
                      className='form-control'
                      min={min}
                      decimalsLimit={maxDecimals}
                      allowDecimals
                      allowNegativeValue={false}
                      value={recipient?.selectedAmount}
                      defaultValue={
                        recipient?.selectedAmount === '0' || !recipient?.selectedAmount
                          ? 0
                          : recipient?.selectedAmount
                      }
                      intlConfig={{ locale: 'en-US' }}
                      onValueChange={(value) => handleUtxoCurrencyInputChange(value, recipient?.address)}
                    />
                    <div className='create-trx-modal-icon-netwrok-2'>
                      <Blockchain
                        showText={false}
                        assetType={selectedAsset?.[0]?.type?.toUpperCase()}
                        blockchain={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              selectedAsset?.[0]?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                        symbol={protocols
                          ?.find(
                            (protocol) =>
                              protocol?.blockchain?.toLowerCase() ===
                              selectedAsset?.[0]?.blockchain?.toLowerCase(),
                          )
                          ?.currency?.toLowerCase()}
                      />
                    </div>
                  </div>
                  {selectedRecipient?.length > 1 && (
                    <div
                      className='create-trx-request-modal-icon'
                      onClick={() => handleRemoveRecipient(recipient?.address)}
                    >
                      <DeleteIcon className='create-trx-request-modal-icon-svg amount' />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </>
      )}
      <div className='create-trx-request-modal-button'>
        <Button
          fullWidth
          onClick={submitCallback}
          disabled={
            !isUtxo
              ? !selectedAmount || selectedAmount == 0 || (!selectedFeePriority?.length && !staticTrxFee)
              : selectedRecipient?.some(
                  (recipient) => !recipient?.selectedAmount || parseFloat(recipient?.selectedAmount) === 0,
                ) ||
                Math.ceil(
                  selectedRecipient?.reduce((accumulator, recipient) => {
                    return accumulator + (parseFloat(recipient?.selectedAmount) || 0);
                  }, 0),
                ) <= 0 ||
                parseFloat(max) <
                  parseFloat(
                    selectedRecipient?.reduce((accumulator, recipient) => {
                      return accumulator + (parseFloat(recipient?.selectedAmount) || 0);
                    }, 0),
                  )
          }
        >
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestAmountStep;
