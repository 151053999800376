import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GuestLayout from '../components/GuestLayout';
import { ReactComponent as AtIcon } from '../assets/icons/at.svg';
import { ReactComponent as LockIcon } from '../assets/icons/lock.svg';
import { T, t } from '../components/T';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import {
  dashboardState,
  loginUser,
  LOGIN_ATTEMPT,
  STATE_REGISTER_PSP,
  STATE_DASHBOARD,
  STATE_PENDING_VERIFICATION,
} from '../redux/slices/authSlice';
import { loginApi } from '../api/endpoints';
import Textfield from '../components/Textfield/Textfield';
import Button from '../components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { ROUTE_LOGIN_ATTEMPT, ROUTE_REGISTER } from '../routes/routes';
import { authRoutesGuardsMapping } from '../helpers/constants';

const SignIn = () => {
  const navigate = useNavigate();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const signInClickHandler = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    setIsLoading(true);
    localStorage.clear();
    loginApi({
      item: {
        email: data?.email,
        password: data?.password,
        reCaptchaToken: token,
      },
    })
      .then((res) => {
        dispatch(loginUser(res.data.data.item.websocketToken));
        if (
          res.data.data.item.state === STATE_DASHBOARD ||
          res.data.data.item.state === STATE_REGISTER_PSP ||
          res.data.data.item.state === STATE_PENDING_VERIFICATION
        ) {
          dispatch(dashboardState(LOGIN_ATTEMPT));
          navigate(ROUTE_LOGIN_ATTEMPT);
        } else {
          dispatch(dashboardState(res.data.data.item.state));
          if (res?.data?.data?.item?.state) {
            navigate(authRoutesGuardsMapping[res.data.data.item.state?.toLowerCase()]);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <GuestLayout>
      <h2 className='fw-bolder'>
        <T>app.sing-in-title</T>
      </h2>
      <form onSubmit={handleSubmit(signInClickHandler)} className='signin-form-wrapper'>
        <div>
          <div className='mb-3'>
            <Controller
              name='email'
              control={control}
              rules={{
                required: t(lng, 'app.email-is-required'),
                validate: (value) =>
                  /^[A-Z0-9._]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) || t(lng, 'app.email-is-invalid'),
              }}
              render={({ field }) => {
                return (
                  <>
                    <label className='guest-textfield-label'>
                      <T>app.email</T>
                    </label>
                    <Textfield
                      id='email'
                      trim
                      type='text'
                      size='lg'
                      disabled={isLoading}
                      icon={<AtIcon />}
                      fullWidth
                      placeholder={t(lng, 'app.email-placeholder')}
                      showValidationEndIcons
                      error={errors?.email?.message}
                      {...field}
                    />
                  </>
                );
              }}
            />
          </div>
          <Controller
            name='password'
            control={control}
            rules={{
              required: t(lng, 'app.password-is-required'),
            }}
            render={({ field }) => {
              return (
                <>
                  <label className='guest-textfield-label'>
                    <T>app.password-placeholder</T>
                  </label>
                  <Textfield
                    id='pass'
                    trim
                    type='password'
                    size='lg'
                    disabled={isLoading}
                    icon={<LockIcon />}
                    fullWidth
                    placeholder={t(lng, 'app.password-placeholder')}
                    showValidationEndIcons
                    error={errors?.password?.message}
                    {...field}
                  />
                </>
              );
            }}
          />
          <div className='mt-3'>
            <Link to='/forgot-password'>
              <T>app.forgot.password</T>
            </Link>
          </div>
          <div>
            <ReCAPTCHA
              size='invisible'
              type='image'
              sitekey='6LdbiH8iAAAAAJk5hD-AQcNi2RsBSpjIKyDv2v93'
              ref={recaptchaRef}
            />
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className='text-center mt-5'>
              <div className='spinner-border text-success' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='d-grid mt-4'>
              <Button fullWidth disabled={!isValid}>
                <T>app.login-button</T>
              </Button>
            </div>
          )}
          <div className='guest-layout-footer'>
            <T>app.signup.no.account</T> <Link to={ROUTE_REGISTER}>Sign up</Link>
          </div>
        </div>
      </form>
    </GuestLayout>
  );
};

export default SignIn;
