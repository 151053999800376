import React, { useEffect, useState } from 'react';
import GuestLayout from '../components/GuestLayout';
import { T, t } from '../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ResendIcon } from '../assets/icons/refresh.svg';
import { FaCheck } from 'react-icons/fa';
import { dashboardState } from '../redux/slices/authSlice';
import { resendEmailCodeApi, verifyEmailApi } from '../api/endpoints';
import Textfield from '../components/Textfield/Textfield';
import Button from '../components/Button/Button';
import { lengthValidator } from '../helpers/validation';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DOWNLOAD_APP } from '../routes/routes';
import TagManager from 'react-gtm-module';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const authToken = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const formDataInitialState = {
    code: {
      hasChanged: false,
      payload: '',
      valid: false,
      error: '',
    },
  };
  const [formData, setFormData] = useState(formDataInitialState);

  useEffect(() => {
    if (formData.code.valid) {
      setIsLoading(true);
      verifyEmailApi(
        { item: { code: formData.code.payload } },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
        .then((res) => {
          if (res.data.data.item.state) {
            dispatch(dashboardState(res.data.data.item.state));
            navigate(ROUTE_DOWNLOAD_APP);
          }
        })
        .catch(() => {
          setFormData({
            ...formData,
            code: {
              ...formData.code,
              valid: false,
              error: t(lng, 'app.invalid.verification.code'),
            },
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [formData.code.valid]);

  const changeCodeHandler = (e) => {
    const validator = lengthValidator(e.target.value, 6, 6, 'verify-code-must-be-six-symbols');
    setFormData({
      ...formData,
      code: {
        hasChanged: true,
        payload: e.target.value,
        valid: validator.status,
        error: t(lng, validator.message),
      },
    });
  };

  const resendEmailHandler = () => {
    setIsLoading(true);
    setFormData(formDataInitialState);
    resendEmailCodeApi({
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Length': 0,
        Accept: '*/*',
        Connection: 'keep-alive',
      },
    })
      .then(() => {
        setIsResend(true);
      })
      .catch(() => {
        setIsResend(false);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (window.dataLayer && window.dataLayer?.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'conversion_event_signup_3',
        },
      });
    }
  }, []);

  return (
    <GuestLayout>
      <div className='guest-flow-form-wrapper'>
        <div className='guest-flow-form-main'>
          <h2 className='fw-bolder'>
            <T>app.verify-email-title</T>
          </h2>
          <p className='guest-flow-subtitle'>
            <T>app.verify-email-text</T>
          </p>

          <form onSubmit={(e) => e.preventDefault()}>
            <div
              className={`mt-4 mb-4 position-relative ${
                !formData.code.valid && formData.code.hasChanged && 'invalid-input'
              }`}
            >
              <Textfield
                trim
                size='lg'
                type='number'
                disabled={isLoading}
                fullWidth
                error={formData.code.error}
                placeholder={t(lng, 'app.verify-email-code-placeholder')}
                value={formData.code.payload}
                onChange={changeCodeHandler}
              />
            </div>
          </form>
        </div>
        <div className='mt-2'>
          {isLoading ? (
            <div className='me-2'>
              <div className='spinner-border text-success' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : isResend ? (
            <div className='btn btn-sm btn-dark rounded-4 pointer-events-none'>
              <FaCheck />
            </div>
          ) : (
            <Button size='md' fullWidth onClick={resendEmailHandler}>
              <ResendIcon className='verify-email-button-icon' /> <T>app.resend-code</T>
            </Button>
          )}
        </div>
        <div className='guest-layout-footer'>
          <T>app.issue-sign-in</T>{' '}
          <a href='mailto: support@vaultody.com' className='btn-link text-decoration-underline'>
            <T>app.contact-support</T>
          </a>
        </div>
      </div>
    </GuestLayout>
  );
};

export default VerifyEmail;
