import { currency } from '../../../helpers/currency';
import Button from '../../Button/Button';
import VTooltip from '../../VTooltip/VTooltip';
import { T, t } from '../../T';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from '../../../assets/icons/pencil-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark.svg';
import Textfield from '../../Textfield/Textfield';
import { editWalletApi } from '../../../api/endpoints';
import { useNavigate } from 'react-router-dom';
import { setPage } from '../../../redux/slices/globalSlice';
import VaultIcon from '../../VaultIcon/VaultIcon';
import { vaultTypeFirstTabMapping, vaultTypes } from '../../../helpers/constants';
import { ROUTE_WALLET } from '../../../routes/routes';

const WalletTableRow = ({ wallet, handleSelectWalletId }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const createTrxReqPermission = myProfile.premmisions?.includes('TRANSACTION_REQUESTS_CREATE');
  const walletDetailsViewPermission = myProfile.premmisions?.includes('WALLETS_DETAILS_VIEW');
  const walletUpdatePermission = myProfile.premmisions?.includes('WALLETS_UPDATE');
  const walletAddressCreatePermission = myProfile.premmisions?.includes('WALLETS_ADDRESS_CREATE');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditName, setIsEditName] = useState(false);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [vaultName, setVaultName] = useState(wallet?.name || '');

  const handleChangeName = () => {
    const data = {
      item: {
        name: vaultName,
      },
    };
    if (vaultName !== '') {
      editWalletApi(wallet?.id, data)
        .then(() => setHasBeenEdited(true))
        .catch(() => setVaultName(wallet?.name))
        .finally(() => setIsEditName(false));
    }
  };

  const goToEditPage = (wallet) => {
    navigate(`${ROUTE_WALLET}/${wallet?.id}/#${vaultTypeFirstTabMapping[wallet?.vaultType?.toLowerCase()]}`);
    dispatch(setPage(hasBeenEdited ? vaultName : wallet?.name));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Enter' && isEditName) {
        handleChangeName();
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isEditName, vaultName]);

  return (
    <>
      {!walletDetailsViewPermission && <VTooltip id={`wallet-view-permission-${wallet?.id}`} />}
      <div
        data-tooltip-id={`wallet-view-permission-${wallet?.id}`}
        data-tooltip-content={t(lng, 'app.role.limitation')}
        className={`table-row data ${!walletDetailsViewPermission ? 'inactive-row' : ''}`}
        onClick={() => goToEditPage(wallet)}
      >
        <div className='vault-table-data name'>
          <span className='mobile-cell-title'>
            <T>app.wallet</T>
          </span>
          <div>
            {isEditName ? (
              <div className='name-data' onClick={(e) => e?.stopPropagation()}>
                <Textfield
                  trim
                  type='text'
                  name='addressName'
                  size='sm'
                  error={
                    vaultName?.length > 30 || vaultName?.length < 3
                      ? t(lng, 'app.vault.name.length.error')
                      : undefined
                  }
                  showValidationEndIcons
                  fullWidth
                  placeholder={t(lng, 'app.address.name.placeholder')}
                  value={vaultName}
                  onChange={(e) => setVaultName(e?.target?.value)}
                />
                <CheckIcon
                  className={`addresses-table-data-check-icon ${
                    !vaultName?.length || vaultName?.length > 30 || vaultName?.length < 3 ? 'disabled' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeName();
                  }}
                />
                <div
                  className='addresses-table-data-close-icon icon-close'
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditName(false);
                  }}
                />
              </div>
            ) : (
              <div className='name-data'>
                <VaultIcon
                  id={wallet?.id}
                  name={hasBeenEdited ? vaultName : wallet?.name}
                  color={wallet?.color}
                  vaultType={wallet?.vaultType}
                />
                <div className='name-right'>
                  <div
                    className='name'
                    data-tooltip-id='specific-wallet-name'
                    data-tooltip-content={wallet?.name}
                  >
                    <VTooltip id='specific-wallet-name' />
                    {hasBeenEdited ? vaultName : wallet?.name}
                  </div>
                </div>
                <img
                  data-tooltip-id='disabled-wallet-update'
                  data-tooltip-content={t(lng, 'app.role.limitation')}
                  src={editIcon}
                  className={`addresses-table-data-edit-icon ${walletUpdatePermission ? '' : 'disabled'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (walletUpdatePermission) {
                      setIsEditName(true);
                    }
                  }}
                />
                {!walletUpdatePermission && <VTooltip id='disabled-wallet-update' />}
              </div>
            )}
          </div>
        </div>
        <div className='vault-table-data sum'>
          <span className='mobile-cell-title'>
            <T>app.available.balance</T>
          </span>
          {currency(wallet?.availableAmount)}
        </div>
        <div className='vault-table-data sum'>
          <span className='mobile-cell-title'>
            <T>app.total.balance</T>
          </span>
          {currency(wallet?.totalAmount)}
        </div>
        <div className='vault-table-data sum'>
          <span className='mobile-cell-title'>
            <T>app.aml.blocked</T>
          </span>
          {currency(wallet?.blockedAmount)}
        </div>
        <div className='vault-table-data sum'>
          <span className='mobile-cell-title'>
            <T>app.allocated</T>
          </span>
          {currency(wallet?.allocatedAmount)}
        </div>
        <div className='vault-table-data settings'>
          {wallet?.vaultType?.toLowerCase() !== vaultTypes.AUTOMATION && (
            <div className='send-receive-button'>
              <Button
                size='sm'
                variant='neutral'
                data-tooltip-id='create-trx-req-button'
                data-tooltip-content={t(lng, 'app.role.limitation')}
                disabled={!parseInt(wallet?.availableAmount) > 0 || !createTrxReqPermission}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelectWalletId(wallet, 'send', wallet?.vaultType);
                }}
              >
                {!createTrxReqPermission && <VTooltip id='create-trx-req-button' />}
                <i className='icon-send' />
                <T>app.send</T>
              </Button>
            </div>
          )}
          <div className='send-receive-button'>
            <Button
              size='sm'
              variant='neutral'
              data-tooltip-id={`create-wallet-address-button-${wallet?.id}`}
              data-tooltip-content={
                !walletAddressCreatePermission ? t(lng, 'app.role.limitation') : t(lng, 'app.backup.secure')
              }
              disabled={!walletAddressCreatePermission || !wallet?.isBackedUp}
              onClick={(event) => {
                event.stopPropagation();
                handleSelectWalletId(wallet, 'receive', wallet?.vaultType);
              }}
            >
              {(!walletAddressCreatePermission || !wallet?.isBackedUp) && (
                <VTooltip id={`create-wallet-address-button-${wallet?.id}`} />
              )}
              <i className='icon-receive' />
              <T>app.recive</T>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTableRow;
