import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import './EditWallet.css';
import { useNavigate } from 'react-router-dom';
import { editWalletApi } from '../../api/endpoints';
import { walletColors } from '../../helpers/constants';
import { setWalletColor } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../T';
import { ROUTE_WALLETS } from '../../routes/routes';
import VaultIcon from '../VaultIcon/VaultIcon';
import Textfield from '../Textfield/Textfield';
import VTooltip from '../VTooltip/VTooltip';
import editIcon from '../../assets/icons/pencil-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/checkmark.svg';

const EditWallet = ({ page, id, setPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const walletColor = useSelector((state) => state.userReducer.walletColor);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [colorMenu, setColorMenu] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [vaultName, setVaultName] = useState(page || '');
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [colorWallet, setColorWalelt] = useState(walletColor);
  const wrapperRef = useRef('color-menu');
  const walletUpdatePermission = myProfile.premmisions?.includes('WALLETS_UPDATE');

  useClickOutside(wrapperRef, () => {
    setColorMenu(false);
  });

  const approveChangeColor = (colorItem) => {
    setColorWalelt(colorItem);
    dispatch(setWalletColor(colorItem));
    const data = {
      item: {
        name: hasBeenEdited ? vaultName : page,
        color: colorItem,
      },
    };
    editWalletApi(id, data).finally(() => {
      setColorMenu(false);
    });
  };

  const handleChangeName = () => {
    const data = {
      item: {
        name: vaultName,
      },
    };
    if (vaultName !== '') {
      editWalletApi(id, data)
        .then(() => setHasBeenEdited(true))
        .catch(() => setVaultName(page))
        .finally(() => setIsEditName(false));
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Enter' && isEditName) {
        handleChangeName();
      }
      if (e.key === 'Escape') {
        setIsEditName(false);
        setVaultName(page);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isEditName, vaultName]);

  return (
    <div className='edit-wallet'>
      <div
        className='return'
        onClick={() => {
          navigate(ROUTE_WALLETS);
          setPage(t(lng, 'app.wallets'));
        }}
      >
        <i className='icon-arrow-right' />
      </div>
      <div className={`color-changer`} ref={wrapperRef}>
        <VaultIcon
          onClick={() => {
            if (walletUpdatePermission) {
              setColorMenu(true);
            }
          }}
          id={id}
          name={vaultName}
          color={walletColor}
        />
        {colorMenu && (
          <div className='color-menu'>
            {walletColors.map((colorItem, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    approveChangeColor(colorItem);
                  }}
                  className={`item ${colorItem === colorWallet ? 'selected' : ''}`}
                  style={{ backgroundColor: colorItem }}
                />
              );
            })}
          </div>
        )}
      </div>
      {isEditName ? (
        <div className='name-data' onClick={(e) => e?.stopPropagation()}>
          <Textfield
            trim
            autoFocus
            type='text'
            name='addressName'
            size='sm'
            error={
              vaultName?.length > 30 || vaultName?.length < 3
                ? t(lng, 'app.vault.name.length.error')
                : undefined
            }
            showValidationEndIcons
            fullWidth
            focused
            placeholder={t(lng, 'app.address.name.placeholder')}
            value={vaultName}
            onChange={(e) => setVaultName(e?.target?.value)}
          />
          <CheckIcon
            className={`edit-wallet-check-icon ${
              !vaultName?.length || vaultName?.length > 30 || vaultName?.length < 3 ? 'disabled' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeName();
            }}
          />
          <div
            className='icon-close'
            onClick={(e) => {
              e.stopPropagation();
              setIsEditName(false);
              setVaultName(page);
            }}
          />
        </div>
      ) : (
        <div className='name-data'>
          <div className='title' data-tooltip-id='specific-wallet-name' data-tooltip-content={page}>
            <VTooltip id='specific-wallet-name' />
            {hasBeenEdited ? vaultName : page}
          </div>
          <img
            data-tooltip-id='disabled-wallet-update'
            data-tooltip-content={t(lng, 'app.role.limitation')}
            src={editIcon}
            className={`wallet-edit-edit-icon ${walletUpdatePermission ? '' : 'disabled'}`}
            onClick={(e) => {
              e.stopPropagation();
              if (walletUpdatePermission) {
                setIsEditName(true);
              }
            }}
          />
          {!walletUpdatePermission && <VTooltip id='disabled-wallet-update' />}
        </div>
      )}
    </div>
  );
};
export default EditWallet;
