import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import ActionModal from '../ActionModal';
import { useSelector } from 'react-redux';
import './CreateTransactionRequestNew.css';
import Stepper from '../../Stepper/Stepper';
import { useState, useEffect } from 'react';
import {
  getBlockchainStrategiesFeesApi,
  getInternalAddressesSearchApi,
  postWalletTrxStaticFeeApi,
  getAddressesByAssetIdApi,
  getWalletAddressesApi,
  getAssetsByVaultApi,
  getWalletsApi,
  createTrxDraftApi,
} from '../../../api/endpoints';
import { ReactComponent as VaultIcon } from '../../../assets/icons/vault-icon2.svg';
import { ReactComponent as AssetIcon } from '../../../assets/icons/asset-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/destination-icon.svg';
import { ReactComponent as AmountIcon } from '../../../assets/icons/amount-icon2.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/icons/money-icon.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/note-icon.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import CreateTransactionRequestVaultStep from './components/CreateTransactionRequestVaultStep';
import CreateTransactionRequestDestinationStep from './components/CreateTransactionRequestDestinationStep';
import CreateTransactionRequestAmountStep from './components/CreateTransactionRequestAmountStep';
import CreateTransactionRequestNoteStep from './components/CreateTransactionRequestNoteStep';
import CreateTransactionRequestSummaryStep from './components/CreateTransactionRequestSummaryStep';
import CreateTransactionRequestAssetStep from './components/CreateTransactionRequestAssetStep';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { btcFeeSteps, flexibleFeeSteps, staticFeeSteps } from '../../../helpers/transactionrequests/steps';
import CreateTransactionRequestStrategyStep from './components/CreateTransactionRequestStrategyStep';
import { vaultTypes } from '../../../helpers/constants';
import { blockchainTypes } from '../../../helpers/blockchain/blockchain';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';

const CreateTransactionRequestNew = ({
  toggleFunction,
  successCallback,
  prefilledWallet,
  prefilledAddress,
  prefilledAsset,
  isDraft,
}) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [note, setNote] = useState('');
  const [assets, setAssets] = useState();
  const [wallets, setWallets] = useState();
  const [internalWallets, setInternalWallets] = useState();
  const [addresses, setAddresses] = useState();
  const [gasTanker, setGasTanker] = useState();
  const [searchResultsAddresses, setSearchResultsAddresses] = useState();
  const [success, setSuccess] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [selectedInternalWallets, setSelectedInternalWallets] = useState(undefined);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState(prefilledAsset ? [prefilledAsset] : undefined);
  const [selectedWallet, setSelectedWallet] = useState(prefilledWallet ? [prefilledWallet] : undefined);
  const [selectedAddress, setSelectedAddress] = useState(prefilledAddress ? [prefilledAddress] : undefined);
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [selectedFeePriority, setSelectedFeePriority] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState([]);
  const [internalAddresses, setInternalAddresses] = useState();
  const [recipientType, setRecipientType] = useState('one-time');
  const [strategyFees, setStrategyFees] = useState();
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'vault' });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <VaultIcon />,
      text: 'app.select.vault',
      name: 'vault',
      completed: false,
    },
    {
      id: 2,
      icon: <AssetIcon />,
      text: 'app.select.asset',
      name: 'asset',
      completed: false,
    },
    {
      id: 3,
      icon: <LocationIcon />,
      text: 'app.destination',
      name: 'destination',
      completed: false,
    },
    {
      id: 4,
      icon: <AmountIcon />,
      text: 'app.amount.and.fee.priority',
      name: 'amount',
      completed: false,
    },
    { id: 5, icon: <NoteIcon />, text: 'app.note', name: 'note', completed: false },
    { id: 6, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);
  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } = useSwiperSlider(
    setSteps,
    '195px',
    [selectedRecipient, recipientType, selectedAddress],
  );

  const assetBlockchainInfo = protocols.find((protocolVal) => {
    return protocolVal.blockchain === selectedAsset?.[0].blockchain;
  });

  const createTransactionRequest = () => {
    let dataSend = {};
    if (isDraft) {
      dataSend = {
        item: {
          drafts: [
            {
              blockchain: assetBlockchainInfo?.blockchain,
              note: note || '',
              ...(blockchainTypes.UTXO ? { prepareStrategy: selectedStrategy?.[0]?.value } : {}),
              vaultId: selectedWallet?.[0]?.id,
              assetId: selectedAsset?.[0]?.assetId,
              feePriority: selectedFeePriority?.[0]?.value,
              ...(blockchainTypes.UTXO
                ? {
                    senders: selectedAddress?.map((sender) => ({
                      address: sender?.address,
                    })),
                  }
                : {}),
              tokenData: {
                contractAddress: selectedAsset?.[0]?.assetData?.contract,
                symbol: selectedAsset?.[0]?.symbol,
              },
              recipients: selectedRecipient?.map((recepient) => ({
                address: recepient?.address,
                amount:
                  assetBlockchainInfo?.type === blockchainTypes.UTXO
                    ? recepient?.selectedAmount
                    : selectedAmount?.toString(),
              })),
            },
          ],
        },
      };
      createTrxDraftApi(dataSend).then(() => successCallback());
    } else {
      if (assetBlockchainInfo?.type === blockchainTypes.UTXO) {
        dataSend = {
          item: {
            blockchain: assetBlockchainInfo?.blockchain,
            note: note || '',
            recipients: selectedRecipient?.map((recepient) => ({
              id: (Math.random() + 1).toString(36).substring(7),
              address: recepient?.address,
              amount: recepient.selectedAmount,
            })),
            prepareStrategy: selectedStrategy?.[0]?.value,
            feePriority: selectedFeePriority?.[0]?.value,
          },
        };
      } else {
        dataSend = {
          item: {
            blockchain: assetBlockchainInfo?.blockchain,
            note: note || '',
            sender: selectedAddress?.[0]?.address,
            recipient: selectedRecipient?.[0]?.address || selectedRecipient?.address,
            amount: selectedAmount?.toString(),
            feePriority: selectedFeePriority?.[0]?.name?.toLowerCase(),
          },
        };
      }
      if (selectedAsset?.[0]?.type?.toUpperCase() === 'TOKEN') {
        dataSend = {
          item: {
            ...dataSend.item,
            ...{ token: selectedAsset?.[0]?.assetData?.contract },
          },
        };
      }
      postWalletTrxStaticFeeApi(
        selectedWallet?.[0]?.id,
        assetBlockchainInfo?.type || blockchainTypes?.ACCOUNT,
        selectedAsset?.[0]?.type?.toUpperCase() === 'TOKEN'
          ? `token/erc-20`
          : selectedAsset?.[0]?.type?.toLowerCase(),
        assetBlockchainInfo?.blockchainStaticTxFee ? '/static-fee' : '',
        dataSend,
      ).then(() => {
        setSuccess(true);
        successCallback();
      });
    }
  };

  const handleChooseAmount = (amount, recipientAddress) => {
    if (recipientAddress) {
      setSelectedRecipient((prev) => {
        if (prev?.length) {
          return prev?.map((recipient) => {
            if (recipient?.address === recipientAddress) {
              return { ...recipient, selectedAmount: amount };
            }
            return recipient;
          });
        }
      });
    } else {
      const availableAmount =
        selectedAddress?.[0]?.availableAmount || parseFloat(selectedAsset?.[0]?.assetData?.availableAmount);
      if (parseFloat(amount) <= parseFloat(availableAmount)) {
        setSelectedAmount(amount);
      }
    }
  };

  const handleChooseFeePriority = (feePriority) => setSelectedFeePriority(feePriority);

  const handleChooseStrategy = (strategy) => setSelectedStrategy(strategy);

  const handleChooseWallet = (wallets) => {
    setSelectedWallet(wallets);
    setSelectedAsset(undefined);
    setSelectedAddress(undefined);
    setSelectedAmount(0);
    setSelectedFeePriority([]);
    setSelectedRecipient(undefined);
    setSteps((prev) =>
      prev?.map((step) => {
        return { ...step, completed: false };
      }),
    );
  };

  const handleChooseInternalWallet = (wallets) => {
    setSelectedInternalWallets(wallets);
  };

  const handleChooseAsset = (assets) => {
    setSelectedAmount(0);
    setSelectedAsset(assets);
    setSelectedFeePriority([]);
    setSelectedAddress(undefined);
    setSelectedRecipient(undefined);
    handleToAddressTypeField('one-time');
    setSteps((prev) =>
      prev?.map((step, index) => {
        if (index === 0) {
          return step;
        }
        return { ...step, completed: false };
      }),
    );
    getAddressesByAssetIdApi(
      assets?.[0]?.assetId,
      selectedWallet?.[0]?.id,
      assets?.[0]?.blockchain,
      typeUser,
      {
        params: {
          ...(selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
            ? { linkStatus: 'linked' }
            : {}),
          addressType:
            selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
              ? ['smart-deposit']
              : ['deposit'],
        },
      },
    ).then((res) => {
      setAddresses(res?.data?.item);
    });
  };

  const handleChooseAddress = (addresses) => {
    setSelectedAddress(addresses);
  };

  const handleChooseRecipient = (recipients, isAddEvent, isInternal) => {
    if (assetBlockchainInfo?.type === blockchainTypes.UTXO) {
      if (isAddEvent) {
        setSelectedRecipient((prev) => (prev?.length > 0 ? [...prev, ...recipients] : [...recipients]));
      } else {
        setSelectedRecipient((prev) => {
          if (prev?.length > 0) {
            if (isInternal) {
              const recipientsWithoutInternalAddresses = prev?.filter((recipient) => {
                return !recipient?.totalAmountInUsd;
              });
              return [...recipientsWithoutInternalAddresses, ...recipients];
            } else {
              const recipientsWithoutContacts = prev?.filter((recipient) => !recipient?.name);
              return [...recipientsWithoutContacts, ...recipients];
            }
          } else {
            return recipients;
          }
        });
      }
    } else {
      setSelectedRecipient(recipients);
    }
  };

  const handleChangeNote = (e) => setNote(e?.target?.value);

  const handleRemoveRecipient = (address) => {
    setSelectedRecipient(
      (prev) => prev?.length > 0 && prev?.filter((recipient) => recipient?.address !== address),
    );
  };
  // THIS IS FOR THE TABS IN THE DESTINATION STEP "TO" ADDRESS
  const handleToAddressTypeField = (type) => {
    setRecipientType(type);
    if (assetBlockchainInfo?.type !== blockchainTypes.UTXO) {
      handleChooseRecipient([]);
      handleChooseInternalWallet([]);
    }
  };

  const internalAddressesSearchFunc = (searchText) => {
    if (searchText?.length > 0) {
      getInternalAddressesSearchApi(
        selectedInternalWallets?.[0]?.id,
        typeUser,
        selectedAsset?.[0]?.blockchain,
        {
          params: { limit: 50, item: searchText },
        },
      ).then((res) => {
        setInternalAddresses(
          res?.data?.items?.map((address) => {
            if (!address?.name) {
              address.name = t(lng, 'app.no.name');
            }
            return address;
          }),
        );
      });
    } else {
      if (typeUser && selectedInternalWallets?.[0]?.id) {
        getWalletAddressesApi(selectedInternalWallets?.[0]?.id, {
          params: {
            limit: 50,
            blockchain: selectedAsset?.[0]?.blockchain,
          },
          paramsSerializer: {
            indexes: null, // by default: false
          },
        }).then((res) =>
          setInternalAddresses(
            res?.data?.items?.map((address) => {
              if (!address?.name) {
                address.name = t(lng, 'app.no.name');
              }
              return address;
            }),
          ),
        );
      }
    }
  };

  const handleAddressSearch = (searchText) => {
    if (searchText?.length > 0) {
      getInternalAddressesSearchApi(selectedWallet?.[0]?.id, typeUser, selectedAsset?.[0]?.blockchain, {
        params: {
          limit: 50,
          item: searchText,
          assetId: selectedAsset?.[0]?.assetId,
          ...(selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
            ? { linkStatus: 'linked' }
            : {}),
          addressType:
            selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
              ? ['smart-deposit']
              : ['deposit'],
        },
      }).then((res) =>
        setSearchResultsAddresses({
          ...addresses,
          addresses: res?.data?.items?.map((address) => ({
            ...address,
            availableAmount: address?.balances?.[0]?.assetData?.availableAmount,
          })),
          shouldUse: true,
        }),
      );
    } else {
      setSearchResultsAddresses({
        ...addresses,
        addresses: [],
        shouldUse: false,
      });
      if (
        selectedAsset?.[0]?.assetId &&
        selectedWallet?.[0]?.id &&
        selectedAsset?.[0]?.blockchain &&
        typeUser
      ) {
        getAddressesByAssetIdApi(
          selectedAsset?.[0]?.assetId,
          selectedWallet?.[0]?.id,
          selectedAsset?.[0]?.blockchain,
          typeUser,
          {
            params: {
              ...(selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
                ? { linkStatus: 'linked' }
                : {}),
              addressTypes:
                selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
                  ? ['smart-deposit']
                  : ['deposit'],
            },
          },
        ).then((res) => {
          setAddresses(res?.data?.item);
        });
      }
    }
  };

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({
        params: {
          type: typeUser?.toUpperCase(),
          limit: 0,
          vaultTypes: ['GENERAL', 'SMART'],
        },
      }).then((res) => setWallets(res.data.items));
    }
  }, [typeUser]);

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 } }).then((res) =>
        setInternalWallets(res.data.items),
      );
    }
  }, [typeUser]);

  useEffect(() => {
    if (assetBlockchainInfo?.blockchainStaticTxFee !== undefined) {
      const assetTypeSteps = assetBlockchainInfo?.blockchainStaticTxFee
        ? staticFeeSteps
        : assetBlockchainInfo?.type === blockchainTypes.UTXO
          ? btcFeeSteps
          : flexibleFeeSteps;
      setSteps(assetTypeSteps);
    }
  }, [assetBlockchainInfo?.blockchain]);

  useEffect(() => {
    if (!selectedWallet?.[0]?.id) {
      return;
    }
    getAssetsByVaultApi(selectedWallet?.[0]?.id).then((res) => {
      setAssets(
        res?.data?.items?.map((item) => ({
          ...item,
          id: `${item?.assetId}-${item?.blockchain}-${item?.network}`,
        })),
      );
    });
  }, [selectedWallet?.[0]?.id]);

  // GET ADDRESSES WHEN THE ASSET IS PREFILLED
  useEffect(() => {
    if (!prefilledAsset) {
      return;
    }
    getAddressesByAssetIdApi(
      prefilledAsset?.assetId,
      selectedWallet?.[0]?.id,
      prefilledAsset?.blockchain,
      typeUser,
      {
        params: {
          ...(selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
            ? { linkStatus: 'linked' }
            : {}),
          addressType:
            selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART
              ? ['smart-deposit']
              : ['deposit'],
        },
      },
    ).then((res) => {
      setAddresses(res?.data?.item);
    });
  }, []);

  useEffect(() => {
    if (!selectedAsset?.[0]?.assetId || !selectedInternalWallets?.[0]?.id) {
      return;
    }
    getWalletAddressesApi(selectedInternalWallets?.[0]?.id, {
      params: {
        limit: 50,
        blockchain: selectedAsset?.[0]?.blockchain,
      },
      paramsSerializer: {
        indexes: null, // by default: false
      },
    }).then((res) => {
      setInternalAddresses(
        res?.data?.items?.map((address) => {
          if (!address?.name) {
            address.name = t(lng, 'app.no.name');
          }
          return address;
        }),
      );
    });
  }, [selectedAsset?.[0]?.assetId, selectedInternalWallets?.[0]?.id]);

  useEffect(() => {
    setGasTanker(undefined);
    if (selectedWallet?.[0]?.vaultType?.toLowerCase() === vaultTypes.SMART) {
      getWalletAddressesApi(selectedWallet?.[0]?.id, {
        params: { limit: 1, addressType: ['station'], blockchain: selectedAsset?.[0]?.blockchain },
      }).then((res) => setGasTanker(res?.data?.items?.[0]));
    }
  }, [selectedAsset?.[0]?.assetId]);

  // GET BLOCKCHAIN AND NETWORK STRAGIES FEES
  useEffect(() => {
    if (selectedAsset?.[0]?.assetId && !assetBlockchainInfo?.blockchainStaticTxFee) {
      getBlockchainStrategiesFeesApi(selectedAsset?.[0]?.blockchain, selectedAsset?.[0]?.network).then(
        (res) => setStrategyFees(res?.data?.item),
      );
    }
  }, [selectedAsset?.[0]?.assetId]);

  useEffect(() => {
    if (prefilledAsset || prefilledAddress) {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(2);
      }
      setCurrentStep({ id: 3, name: 'destination' });
      setSteps((prev) =>
        prev?.map((step) => {
          if (step?.id === 2) {
            return {
              ...step,
              completed: true,
            };
          }
          return step;
        }),
      );
      return;
    }
    if (prefilledWallet) {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(1);
      }
      setCurrentStep({ id: 2, name: 'asset' });
      setSteps((prev) =>
        prev?.map((step) => {
          if (step?.id === 1) {
            return {
              ...step,
              completed: true,
            };
          }
          return step;
        }),
      );
    }
  }, []);

  return (
    <>
      {!success && (
        <VModal classHandler={`create-transaction-request-modal`} toggleFunction={toggleFunction}>
          <div className='create-transaction-request-modal-wrapper'>
            <div className='create-transaction-request-modal-side'>
              <span className='create-transaction-request-modal-side-heading'>
                {`${t(lng, 'app.step')} ${currentStep?.id}`}
              </span>
              <Stepper
                onSelectStepCallback={(stepIndex, stepName) => {
                  setCurrentStep({ id: stepIndex, name: stepName });
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(stepIndex - 1);
                  }
                }}
                currentStepId={currentStep?.id}
                steps={steps}
              />
            </div>
            <div className='create-transaction-request-modal-content'>
              <div className='create-transaction-request-modal-heading'>
                <div className='create-transaction-request-modal-heading-wrapper'>
                  <span>{isDraft ? <T>app.new.trx.draft</T> : <T>app.new.trans.request</T>}</span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <span className='create-transaction-request-modal-side-sub-heading'>
                    {
                      stepDescriptionMapping(
                        assetBlockchainInfo?.type === blockchainTypes.UTXO,
                        assetBlockchainInfo?.blockchainStaticTxFee,
                      )[currentStep?.name]?.icon
                    }
                    {t(
                      lng,
                      stepDescriptionMapping(
                        assetBlockchainInfo?.type === blockchainTypes.UTXO,
                        assetBlockchainInfo?.blockchainStaticTxFee,
                      )[currentStep?.name]?.text,
                    )}
                  </span>
                  <span className='create-transaction-request-modal-step-desc'>
                    {t(
                      lng,
                      stepDescriptionMapping(
                        assetBlockchainInfo?.type === blockchainTypes.UTXO,
                        assetBlockchainInfo?.blockchainStaticTxFee,
                      )[currentStep?.name]?.subText,
                    )}
                  </span>
                </div>
              </div>
              <div
                className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
                style={{ height: swiperContainerHeight }}
              >
                <Swiper
                  ref={swiperRef}
                  spaceBetween={50}
                  speed={800}
                  allowTouchMove={false}
                  slidesPerView={1}
                  onSlideChangeTransitionStart={() => setSliding(true)}
                  onSlideChangeTransitionEnd={() => setSliding(false)}
                >
                  <SwiperSlide>
                    <CreateTransactionRequestVaultStep
                      wallets={wallets}
                      handleChooseWallet={handleChooseWallet}
                      selectedWallet={selectedWallet}
                      prefilledWallet={prefilledWallet}
                      submitCallback={() => {
                        setCurrentStep({ id: 2, name: 'asset' });
                        handleCompleteStep(1);
                        handleSwiperNextSlide();
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CreateTransactionRequestAssetStep
                      assets={assets}
                      handleChooseAsset={handleChooseAsset}
                      selectedWallet={selectedWallet}
                      selectedAsset={selectedAsset}
                      submitCallback={() => {
                        setCurrentStep({ id: 3, name: 'destination' });
                        handleCompleteStep(2);
                        handleSwiperNextSlide();
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CreateTransactionRequestDestinationStep
                      gasTanker={gasTanker}
                      addresses={searchResultsAddresses?.shouldUse ? searchResultsAddresses : addresses}
                      wallets={internalWallets}
                      recipientType={recipientType}
                      internalAddresses={internalAddresses}
                      isUtxo={assetBlockchainInfo?.type === blockchainTypes.UTXO}
                      handleChooseAddress={handleChooseAddress}
                      handleChooseRecipient={handleChooseRecipient}
                      handleRemoveRecipient={handleRemoveRecipient}
                      selectedInternalWallets={selectedInternalWallets}
                      handleChooseInternalWallet={handleChooseInternalWallet}
                      handleToAddressTypeField={handleToAddressTypeField}
                      internalAddressesSearchFunc={internalAddressesSearchFunc}
                      selectedRecipient={selectedRecipient}
                      selectedAddress={selectedAddress}
                      selectedAsset={selectedAsset}
                      addressesSearchFunc={handleAddressSearch}
                      submitCallback={() => {
                        setCurrentStep({ id: 4, name: 'amount' });
                        handleCompleteStep(3);
                        handleSwiperNextSlide();
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CreateTransactionRequestAmountStep
                      min={assetBlockchainInfo?.minimumTransactionAmount}
                      max={
                        selectedAddress?.[0]?.availableAmount ||
                        parseFloat(selectedAsset?.[0]?.assetData?.availableAmount)
                      }
                      isUtxo={assetBlockchainInfo?.type === blockchainTypes.UTXO}
                      strategyFees={strategyFees}
                      selectedAsset={selectedAsset}
                      selectedAmount={selectedAmount}
                      selectedRecipient={selectedRecipient}
                      exchangeRate={addresses?.exchangeRate}
                      handleChooseAmount={handleChooseAmount}
                      selectedFeePriority={selectedFeePriority}
                      handleRemoveRecipient={handleRemoveRecipient}
                      handleChooseFeePriority={handleChooseFeePriority}
                      staticTrxFee={assetBlockchainInfo?.blockchainStaticTxFee}
                      submitCallback={() => {
                        setCurrentStep({
                          id: 5,
                          name: assetBlockchainInfo?.type === blockchainTypes.UTXO ? 'strategy' : 'note',
                        });
                        handleCompleteStep(4);
                        handleSwiperNextSlide();
                      }}
                    />
                  </SwiperSlide>
                  {assetBlockchainInfo?.type === blockchainTypes.UTXO && (
                    <SwiperSlide>
                      <CreateTransactionRequestStrategyStep
                        strategyFees={strategyFees}
                        selectedStrategy={selectedStrategy}
                        selectedFeePriority={selectedFeePriority}
                        handleChooseStrategy={handleChooseStrategy}
                        handleChooseFeePriority={handleChooseFeePriority}
                        submitCallback={() => {
                          setCurrentStep({ id: 6, name: 'note' });
                          handleCompleteStep(5);
                          handleSwiperNextSlide();
                        }}
                      />
                    </SwiperSlide>
                  )}
                  <SwiperSlide>
                    <CreateTransactionRequestNoteStep
                      note={note}
                      handleChangeNote={handleChangeNote}
                      submitCallback={() => {
                        setCurrentStep({
                          id: assetBlockchainInfo?.type === blockchainTypes.UTXO ? 7 : 6,
                          name: 'summary',
                        });
                        handleCompleteStep(assetBlockchainInfo?.type === blockchainTypes.UTXO ? 6 : 5);
                        handleSwiperNextSlide();
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <CreateTransactionRequestSummaryStep
                      note={note}
                      isDraft={isDraft}
                      selectedAsset={selectedAsset}
                      selectedWallet={selectedWallet}
                      selectedAmount={selectedAmount}
                      selectedAddress={selectedAddress}
                      selectedStrategy={selectedStrategy}
                      selectedRecipient={selectedRecipient}
                      exchangeRate={addresses?.exchangeRate}
                      selectedFeePriority={selectedFeePriority}
                      staticTrxFee={assetBlockchainInfo?.blockchainStaticTxFee}
                      isUtxo={assetBlockchainInfo?.type === blockchainTypes.UTXO}
                      submitCallback={() => createTransactionRequest()}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </VModal>
      )}
      {success && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.trans.req.descr</T>
        </ActionModal>
      )}
    </>
  );
};
export default CreateTransactionRequestNew;

const stepDescriptionMapping = (isUtxo, isStaticTrxFee) => ({
  vault: {
    text: 'app.vault.step.desc',
    subText: 'app.vault.step.sub.text',
    icon: <VaultIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  asset: {
    text: 'app.asset.step.desc',
    subText: 'app.asset.step.sub.text',
    icon: <AssetIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  destination: {
    text: 'app.destination.step.desc',
    subText: 'app.destination.step.sub.text',
    icon: <LocationIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  amount: {
    text: isUtxo || isStaticTrxFee ? 'app.amount' : 'app.amount.step.desc',
    subText: isUtxo || isStaticTrxFee ? 'app.amount.descr.tron.btc' : 'app.amount.step.sub.text',
    icon: <AmountIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  strategy: {
    text: 'app.amount.step.desc',
    subText: 'app.amount.step.sub.text',
    icon: <MoneyIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  note: {
    text: 'app.note.step.desc',
    subText: 'app.vanoteult.step.sub.text',
    icon: <NoteIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    text: 'app.summary.step.desc',
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
