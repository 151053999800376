import { T, t } from '../../T';
import VModal from '../../Modal/VModal';
import ActionModal from '../ActionModal';
import { useSelector } from 'react-redux';
import Stepper from '../../Stepper/Stepper';
import { useState, useEffect } from 'react';
import { ReactComponent as NameIcon } from '../../../assets/icons/pencil2-icon.svg';
import { ReactComponent as PassIcon } from '../../../assets/icons/decoded-icon.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/vault-icon2.svg';
import { ReactComponent as DescriptionIcon } from '../../../assets/icons/prompt-icon.svg';
import { ReactComponent as EventsIcon } from '../../../assets/icons/pointer-icon2.svg';
import { ReactComponent as SummaryIcon } from '../../../assets/icons/summary-icon2.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';
import { ReactComponent as DisabledEyeIcon } from '../../../assets/icons/disabled-eye-icon.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import './CreateWebhookModal.css';
import 'swiper/css';
import Button from '../../Button/Button';
import { createWebhookApi, getWalletsApi, getWebhookEventsApi } from '../../../api/endpoints';
import Textfield from '../../Textfield/Textfield';
import { useForm, Controller } from 'react-hook-form';
import Filter from '../../Filter/Filter';
import VaultIcon from '../../VaultIcon/VaultIcon';
import { useSwiperSlider } from '../../../helpers/hooks/useSwiperSlider';
import VTooltip from '../../VTooltip/VTooltip';

const CreateWebhookModal = ({ toggleFunction, successCallback }) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [wallets, setWallets] = useState();
  const [successAdded, setsSuccessAdded] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [currentStep, setCurrentStep] = useState({ id: 1, name: 'name' });
  const [events, setEvents] = useState([]);
  const [isSecretHidden, setIsSecretHidden] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      secret: '',
      vault: [],
      events: [],
      description: '',
    },
  });
  const [steps, setSteps] = useState([
    {
      id: 1,
      icon: <NameIcon />,
      text: 'app.endpoint.url',
      name: 'name',
      completed: false,
    },
    {
      id: 2,
      icon: <PassIcon />,
      text: 'app.signing.secret',
      name: 'secret',
      completed: false,
    },
    {
      id: 3,
      icon: <WalletIcon />,
      text: 'app.select.wallets',
      name: 'vault',
      completed: false,
    },
    { id: 4, icon: <EventsIcon />, text: 'app.events', name: 'events', completed: false },
    {
      id: 5,
      icon: <DescriptionIcon />,
      text: 'app.description',
      name: 'description',
      completed: false,
    },
    { id: 6, icon: <SummaryIcon />, text: 'app.summary', name: 'summary', completed: false },
  ]);

  const { handleCompleteStep, handleSwiperNextSlide, swiperContainerHeight, swiperRef } =
    useSwiperSlider(setSteps);

  const { icon, subText } = stepDescriptionMapping()[currentStep?.name];

  const getPermissibleRoutes = () => {
    getWebhookEventsApi().then((res) => {
      const d = Object.keys(res.data.item).map((i) => {
        return {
          name: res.data.item[i],
          id: i,
        };
      });
      setEvents(d);
    });
  };

  const createAPIKey = (data) => {
    const payload = {
      item: {
        url: data?.name,
        secret: data?.secret,
        events: data?.events?.length ? data?.events?.map((perm) => perm?.id) : [],
        walletIds: data?.vault?.map((vault) => vault?.id),
        description: data?.description,
      },
    };
    createWebhookApi(payload).then((res) => {
      setsSuccessAdded(true);
      successCallback();
    });
  };

  const handleStepNextButton = (id, name) => {
    setCurrentStep({ id: id, name: name });
    handleCompleteStep(id - 1);
    handleSwiperNextSlide();
  };

  useEffect(() => {
    getPermissibleRoutes();
  }, []);

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({
        params: {
          type: typeUser?.toUpperCase(),
          limit: 0,
        },
      }).then((res) => setWallets(res.data.items));
    }
  }, [typeUser]);

  return (
    <>
      {!successAdded && (
        <VModal classHandler={'create-transaction-request-modal'} toggleFunction={toggleFunction}>
          <div className='create-transaction-request-modal-wrapper'>
            <div className='create-transaction-request-modal-side'>
              <span className='create-transaction-request-modal-side-heading'>
                {`${t(lng, 'app.step')} ${currentStep?.id}`}
              </span>
              <Stepper
                onSelectStepCallback={(stepIndex, stepName) => {
                  setCurrentStep({ id: stepIndex, name: stepName });
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideTo(stepIndex - 1);
                  }
                }}
                currentStepId={currentStep?.id}
                steps={steps}
              />
            </div>
            <div className='create-transaction-request-modal-content'>
              <span className='create-transaction-request-modal-heading'>
                <div className='create-transaction-request-modal-heading-wrapper'>
                  <span>
                    <T>app.create.webhook</T>
                  </span>
                  <div className='mobile-stepper'>
                    <Stepper
                      onSelectStepCallback={(stepIndex, stepName) => {
                        setCurrentStep({ id: stepIndex, name: stepName });
                        if (swiperRef.current && swiperRef.current.swiper) {
                          swiperRef.current.swiper.slideTo(stepIndex - 1);
                        }
                      }}
                      currentStepId={currentStep?.id}
                      steps={steps}
                    />
                  </div>
                  <span className='create-transaction-request-modal-side-sub-heading'>
                    {icon}
                    {t(lng, steps[currentStep?.id - 1]?.text)}
                  </span>
                  <span className='create-transaction-request-modal-step-desc'>{t(lng, subText)}</span>
                </div>
              </span>
              <div
                className={`create-transaction-request-swiper-wrapper ${sliding ? 'sliding' : ''}`}
                style={{ height: swiperContainerHeight }}
              >
                <Swiper
                  ref={swiperRef}
                  spaceBetween={50}
                  speed={800}
                  allowTouchMove={false}
                  slidesPerView={1}
                  onSlideChangeTransitionStart={() => setSliding(true)}
                  onSlideChangeTransitionEnd={() => setSliding(false)}
                >
                  <form onSubmit={handleSubmit(createAPIKey)} className='signin-form-wrapper'>
                    <SwiperSlide>
                      <Controller
                        name='name'
                        control={control}
                        rules={{
                          required: `${t(lng, 'app.endpoint.url')} ${t(lng, 'app.is.required')}`,
                          validate: (value) =>
                            (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(
                              value,
                            ) &&
                              !value.includes('<script>')) ||
                            t(lng, 'app.webhook.url.validate'),
                        }}
                        render={({ field }) => {
                          return (
                            <>
                              <span className='create-trx-request-modal-select-component-label'>
                                <T>app.endpoint.url</T>
                              </span>
                              <Textfield
                                id='name'
                                type='text'
                                name='name'
                                fullWidth
                                size='lg'
                                placeholder={t(lng, 'app.https')}
                                error={errors?.name?.message}
                                {...field}
                              />
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(2, 'secret')}
                          disabled={errors?.name || !dirtyFields?.name}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Controller
                        name='secret'
                        control={control}
                        rules={{
                          required: `${t(lng, 'app.signing.secret')} ${t(lng, 'app.is.required')}`,
                          validate: (value) =>
                            !value.includes('<script>') || t(lng, 'app.webhook.secret.validate'),
                        }}
                        render={({ field }) => {
                          return (
                            <>
                              <label>
                                <T>app.signing.secret</T>
                              </label>
                              <Textfield
                                id='secret'
                                type='password'
                                name='secret'
                                autocomplete='new-password'
                                fullWidth
                                size='lg'
                                placeholder={t(lng, 'app.enter.signing.secret')}
                                error={errors?.secret?.message}
                                {...field}
                              />
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(3, 'vault')}
                          disabled={errors?.secret || !dirtyFields?.secret}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Controller
                        name='vault'
                        control={control}
                        rules={{
                          required: `${t(lng, 'app.wallets')} ${t(lng, 'app.is.required')}`,
                        }}
                        render={({ field }) => {
                          return (
                            <>
                              <label>
                                <T>app.select.wallets</T>
                              </label>
                              <Filter
                                items={wallets}
                                selectAllField
                                fullWidth
                                displayIcon
                                multiSelect
                                placeholder='app.select.vaults.api.key'
                                {...field}
                                selectedItems={field?.value || []}
                                onChange={field?.onChange}
                              />
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          disabled={errors?.vault || !dirtyFields?.vault}
                          fullWidth
                          onClick={() => handleStepNextButton(4, 'events')}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='create-api-key-permissions-wrapper'>
                        <Controller
                          name='events'
                          control={control}
                          rules={{
                            required: `${t(lng, 'app.events.label')} ${t(lng, 'app.is.required')}`,
                          }}
                          render={({ field }) => {
                            return (
                              <>
                                <label>
                                  <T>app.events.label</T>
                                </label>
                                <Filter
                                  {...field}
                                  fullWidth
                                  multiSelect
                                  items={events}
                                  selectAllField
                                  onChange={field?.onChange}
                                  placeholder={t(lng, 'app.events')}
                                  selectedItems={field?.value || []}
                                  customLabelTemplate={(event) => {
                                    return (
                                      <>
                                        <span className='webhook-event-dropdown-template'>
                                          <VTooltip
                                            style={{ textWrap: 'wrap' }}
                                            id={`webhook-event-${event?.id}`}
                                          />
                                          {event?.name}
                                          <div className='question'>
                                            <div
                                              className='icon'
                                              data-tooltip-id={`webhook-event-${event?.id}`}
                                              data-tooltip-content={t(lng, `app.${event?.id?.toLowerCase()}`)}
                                            >
                                              <span className='balance-question-mark'>?</span>
                                            </div>
                                          </div>
                                        </span>
                                      </>
                                    );
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className='create-trx-request-modal-button'>
                        <Button
                          disabled={errors?.events || !dirtyFields?.events}
                          fullWidth
                          onClick={() => handleStepNextButton(5, 'description')}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Controller
                        name='description'
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <label>
                                <T>app.description</T>
                              </label>
                              <div className='create-webhook-modal-amount-input'>
                                <Textfield
                                  id='description'
                                  autocomplete='off'
                                  type='text'
                                  name='description'
                                  fullWidth
                                  variant='textarea'
                                  size='lg'
                                  maxLength={400}
                                  placeholder='Enter description'
                                  error={errors?.description?.message}
                                  {...field}
                                />
                              </div>
                            </>
                          );
                        }}
                      />
                      <div className='create-trx-request-modal-button'>
                        <Button
                          fullWidth
                          onClick={() => handleStepNextButton(6, 'summary')}
                          disabled={errors?.description}
                        >
                          <T>app.next.step</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='create-api-key-summary-wrapper'>
                        <div className='create-api-key-summary-row'>
                          <T>app.endpoint.url</T>
                          <span>{getValues()?.name}</span>
                        </div>
                        <div className='create-api-key-summary-row'>
                          <T>app.signing.secret</T>
                          <span>
                            {isSecretHidden ? getValues()?.secret?.replace(/./g, '✱') : getValues()?.secret}
                            {isSecretHidden ? (
                              <DisabledEyeIcon
                                style={{ marginLeft: 10, cursor: 'pointer' }}
                                onClick={() => setIsSecretHidden((prev) => !prev)}
                              />
                            ) : (
                              <EyeIcon
                                style={{ marginLeft: 10, cursor: 'pointer' }}
                                onClick={() => setIsSecretHidden((prev) => !prev)}
                              />
                            )}
                          </span>
                        </div>
                        {getValues()?.vault?.length ? (
                          <div className='create-api-key-summary-row'>
                            <T>app.wallets</T>
                            <div className='wallet-icons'>
                              {getValues()?.vault?.map((wallet, key) => {
                                if (key < 3) {
                                  return (
                                    <VaultIcon
                                      key={`vault-index-${key}`}
                                      id={wallet?.id}
                                      name={wallet?.name}
                                      color={wallet?.color}
                                    />
                                  );
                                }
                              })}
                              {getValues()?.vault?.length > 3 && (
                                <div className='wallet-icon plus'>{`+ ${
                                  getValues()?.vault?.length - 3
                                }`}</div>
                              )}
                            </div>
                          </div>
                        ) : null}
                        {getValues()?.description ? (
                          <div className='create-api-key-summary-row'>
                            <T>app.description</T>
                            <span>{getValues()?.description}</span>
                          </div>
                        ) : null}
                        {getValues()?.events?.length ? (
                          <div className='create-api-key-summary-row'>
                            <T>app.events.label</T>
                            {getValues()?.events?.length > 2 ? (
                              <div className='create-api-key-summary-many'>
                                {getValues()
                                  ?.events?.slice(0, 2)
                                  ?.map((permission) => permission?.name)
                                  ?.join(', ')}
                                <div className='wallet-icon plus'>{`+ ${
                                  getValues()?.events?.length - 2
                                }`}</div>
                              </div>
                            ) : (
                              <div>
                                {getValues()
                                  ?.events?.map((permission) => permission?.name)
                                  ?.join(', ')}
                              </div>
                            )}
                          </div>
                        ) : null}
                        <Button
                          fullWidth
                          type='submit'
                          onClick={handleSubmit(createAPIKey)}
                          disabled={!isValid}
                        >
                          <i className='icon-submit-for-approval' />
                          <T>app.submit.for.approval</T>
                        </Button>
                      </div>
                    </SwiperSlide>
                  </form>
                </Swiper>
              </div>
            </div>
          </div>
        </VModal>
      )}
      {successAdded && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.api.key.added.for.create</T>
        </ActionModal>
      )}
    </>
  );
};

export default CreateWebhookModal;

const stepDescriptionMapping = () => ({
  name: {
    subText: 'app.webhook.name.step.subtext',
    icon: <NameIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  secret: {
    subText: 'app.webhook.secret.step.subtext',
    icon: <PassIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  vault: {
    subText: 'app.vault.step.sub.text2',
    icon: <WalletIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  events: {
    subText: 'app.webhook.permissions.step.subtext',
    icon: <EventsIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  description: {
    subText: 'app.webhook.description.step.subtext',
    icon: <DescriptionIcon style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
  summary: {
    icon: <SummaryIcon fill='#fff' style={{ marginRight: '10px', marginBottom: '4px' }} />,
  },
});
