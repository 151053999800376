import '../CreateTransactionRequestNew.css';
import Filter from '../../../Filter/Filter';
import Button from '../../../Button/Button';
import ReactLoading from 'react-loading';
import { T } from '../../../T';

const CreateTransactionRequestVaultStep = ({
  wallets,
  handleChooseWallet,
  selectedWallet,
  prefilledWallet,
  submitCallback,
}) => {
  return (
    <div className='create-trx-request-step-one-wrapper'>
      <div className='create-trx-request-modal-select-component-wrapper'>
        <span className='create-trx-request-modal-select-component-label'>
          <T>app.wallet</T>
        </span>
        <Filter
          items={wallets}
          variant='radio'
          selectedItems={selectedWallet?.length > 0 ? selectedWallet : prefilledWallet}
          onChange={handleChooseWallet}
          fullWidth
          displayIcon
          disableFocusShadowEffect
          placeholder={
            wallets === undefined ? (
              <div className='loading'>
                <ReactLoading width={25} height={25} type='spin' color='##020D1C' />
              </div>
            ) : (
              'app.wallets'
            )
          }
        />
      </div>
      <div className='create-trx-request-modal-button'>
        <Button fullWidth onClick={submitCallback} disabled={!selectedWallet}>
          <T>app.next.step</T>
        </Button>
      </div>
    </div>
  );
};

export default CreateTransactionRequestVaultStep;
