import './assets/fonts/Gilroy-Medium.ttf';
import './assets/fonts/Gilroy-Bold.ttf';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import Toast from './components/Toast/Toast';
import AxiosErrorDetailsModal from './components/Modals/AxiosErrorDetailsModal/AxiosErrorDetailsModal';

const tagManagerArgs = {
  gtmId: 'GTM-PMH96K9',
  events: {
    conversion_event_signup_3: 'conversion_event_signup_3',
  },
};
if (window.location.href?.includes('https://my.vaultody.com')) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <AxiosErrorDetailsModal />
      <Toast>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <App />
        </CookiesProvider>
      </Toast>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
