import './ContactDeleteModal.css';
import { ReactComponent as TrashImage } from '../../../../assets/images/trash.svg';
import { T } from '../../../T';
import Blockchain from '../../../Blockchain/Blockchain';
import { useSelector } from 'react-redux';
import CopyButton from '../../../CopyButton/CopyButton';
import Button from '../../../Button/Button';
import VModal from '../../../Modal/VModal';
import { useState } from 'react';
import { deleteContactApi } from '../../../../api/endpoints';

const ContactDeleteModal = ({ addressName, blockchain, address, closeCallback, id }) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDeleteContact = async () => {
    return deleteContactApi(id).then(() => setIsDeleted(true));
  };

  return (
    <VModal classHandler='contacts-delete-modal-vmodal' toggleFunction={closeCallback}>
      <div className='contact-delete-modal'>
        <TrashImage />
        {isDeleted && (
          <>
            <span className='contact-delete-modal-header'>
              <T>app.contact.has.been.deleted</T>
            </span>
            <Button fullWidth size='md' variant='neutral' onClick={closeCallback}>
              <T>app.close</T>
            </Button>
          </>
        )}
        {!isDeleted && (
          <>
            <span className='contact-delete-modal-header'>
              <T>app.are.you.sure.delete.contact</T>
            </span>
            <div className='contact-delete-modal-info-wrapper'>
              <div className='contact-delete-modal-info'>
                <span className='contact-delete-modal-sub-text'>
                  <T>app.contact.name</T>
                </span>
                {addressName}
              </div>
              <div className='contact-delete-modal-info'>
                <span className='contact-delete-modal-sub-text'>
                  <T>app.blockchain</T>
                </span>
                <Blockchain
                  showText
                  blockchain={protocols
                    ?.find((protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase())
                    ?.currency?.toLowerCase()}
                  symbol={protocols
                    ?.find((protocol) => protocol?.blockchain?.toLowerCase() === blockchain?.toLowerCase())
                    ?.currency?.toLowerCase()}
                />
              </div>
              <div className='contact-delete-modal-info'>
                <span className='contact-delete-modal-sub-text'>
                  <T>app.address</T>
                </span>
                <div className='contact-delete-modal-address-wrapper'>
                  {address}
                  <CopyButton element={address} />
                </div>
              </div>
            </div>
            <div className='contact-delete-modal-warning'>
              <T>app.contact.delete.warning</T>
            </div>
            <div className='contact-delete-modal-buttons'>
              <Button fullWidth size='md' variant='delete' onClick={handleDeleteContact}>
                <T>app.yes.remove.contact</T>
              </Button>
              <Button fullWidth size='md' variant='neutral' onClick={closeCallback}>
                <T>app.close</T>
              </Button>
            </div>
          </>
        )}
      </div>
    </VModal>
  );
};

export default ContactDeleteModal;
