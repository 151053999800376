import axios from 'axios';
import { HOST } from '../config/Common';
import { store } from '../redux/index';
import { logoutUser } from '../redux/slices/authSlice';
import Toasts from '../helpers/toasts';
import { ROUTE_HOME, ROUTE_ERROR } from '../routes/routes';
import Button from '../components/Button/Button';
import { setAxiosApiErrorDetailsModalVisible } from '../redux/slices/globalSlice';

const handleErrorPopUpWithDetailsOpen = (errorDetailsFromApi) => {
  let arrayOfErrorMessages = [];
  errorDetailsFromApi?.forEach((err) => {
    if (err?.message && typeof err?.message === 'string') {
      arrayOfErrorMessages.push(err?.message);
    } else {
      if (typeof err?.message === 'object' && Object.values(err?.message)?.length) {
        Object.values(err?.message)?.forEach((obj) => {
          if (typeof obj === 'object' && Object.values(obj)?.length) {
            Object.values(obj).forEach((arrayWithErrors) => {
              if (arrayWithErrors?.length) {
                arrayOfErrorMessages = arrayOfErrorMessages?.length
                  ? [...arrayOfErrorMessages, ...arrayWithErrors]
                  : [...arrayWithErrors];
              }
            });
          }
        });
      }
    }
  });
  store.dispatch(setAxiosApiErrorDetailsModalVisible({ open: true, details: arrayOfErrorMessages }));
};

axios.interceptors.response.use(
  function (response) {
    if (response.headers?.['x-refresh-token']) {
      localStorage.setItem('token', response.headers?.['x-refresh-token']);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    if (error?.response?.data?.error) {
      if (error?.response?.data?.error?.details?.length) {
        if (error?.response?.data?.error?.message) {
          Toasts.error(
            <div className='axios-error-details-toast'>
              <span>{error?.response?.data?.error?.message}</span>
              <Button
                size='sm'
                onClick={() => handleErrorPopUpWithDetailsOpen(error?.response?.data?.error?.details)}
                variant='delete'
              >
                More Details
              </Button>
            </div>,
          );
        }
      } else {
        Toasts.error(error?.response?.data?.error?.message);
      }
    }
    if (error?.response?.data?.error?.code === 'resource_not_found' && error?.response?.status === 404) {
      return window.location.replace(ROUTE_ERROR);
    }
    if (
      error?.response?.data?.error?.code === 'unauthorized' &&
      error?.response?.status === 401 &&
      store.getState()?.authReducer?.isLogged
    ) {
      localStorage.clear();
      store.dispatch(logoutUser());
      window.location.replace(ROUTE_HOME);
    }
    console.log(error?.response?.data);
    return Promise.reject(error?.response?.data);
  },
);

const getHeaders = () => {
  const bearerToken = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
  };
};

export const get = async (url, options, fileDownload) => {
  return await axios({
    url: `${HOST}/${url}`,
    ...getHeaders(),
    method: 'get',
    ...(fileDownload && { responseType: 'blob' }),
    ...options,
  }).then((response) => {
    return {
      status: response?.status,
      data: !fileDownload ? response?.data?.data : response?.data,
      error: response?.data?.error,
    };
  });
};

export const post = async (url, data, options) => {
  return await axios({
    url: `${HOST}/${url}`,
    ...getHeaders(),
    method: 'post',
    ...(data && { data: { data } }),
    ...options,
  }).then((response) => {
    return {
      status: response.status,
      data: response.data,
    };
  });
};

export const patch = async (url, data, options) => {
  return await axios({
    url: `${HOST}/${url}`,
    ...getHeaders(),
    method: 'patch',
    data: { data },
    ...options,
  }).then((response) => {
    return {
      status: response.status,
      data: response.data,
    };
  });
};

export const put = async (url, data, options) => {
  return await axios({
    url: `${HOST}/${url}`,
    ...getHeaders(),
    method: 'put',
    data: { data },
    ...options,
  }).then((response) => {
    return {
      status: response.status,
      data: response.data,
    };
  });
};

export const deleteRest = async (url, options) => {
  return await axios({
    url: `${HOST}/${url}`,
    ...getHeaders(),
    method: 'delete',
    ...options,
  }).then((response) => {
    return {
      status: response.status,
      data: response.data,
    };
  });
};
