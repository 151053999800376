import React, { useState } from 'react';
import { T, t } from '../../components/T';
import { useSelector } from 'react-redux';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import VModal from '../Modal/VModal';
import ActionModal from './ActionModal';
import { changeProfilePasswordApi } from '../../api/endpoints';
import Textfield from '../Textfield/Textfield';
import Button from '../Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { passwordRegex } from '../../helpers/validation';

const ChangePassword = ({ toggleFunction }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [isLoading, setIsLoading] = useState(false);
  const [sussess, setSussess] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const signUpClickHandler = (data) => {
    setIsLoading(true);
    const payload = {
      item: {
        oldPassword: data?.oldPassword,
        newPassword: data?.password,
      },
    };
    changeProfilePasswordApi(payload)
      .then(() => setSussess(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {!sussess && (
        <VModal classHandler='change-password' toggleFunction={toggleFunction}>
          <h2 className='fw-bolder mt-2 mb-5'>
            <T>app.reset.password</T>
          </h2>
          <form onSubmit={handleSubmit(signUpClickHandler)}>
            <div className='change-password-textfield-wrapper'>
              <Controller
                name='oldPassword'
                control={control}
                rules={{
                  required: t(lng, 'app.password-is-required'),
                }}
                render={({ field }) => {
                  return (
                    <Textfield
                      id='oldpass'
                      trim
                      type='password'
                      autoComplete='new-password'
                      disabled={isLoading}
                      icon={<LockIcon />}
                      fullWidth
                      size='lg'
                      placeholder={t(lng, 'app.old.pass')}
                      showValidationEndIcons
                      error={errors?.oldPassword?.message}
                      {...field}
                    />
                  );
                }}
              />
            </div>
            <div className='change-password-textfield-wrapper'>
              <Controller
                name='password'
                control={control}
                rules={{
                  required: t(lng, 'app.password-is-required'),
                  validate: (value) => passwordRegex.test(value) || t(lng, 'app.password-is-invalid'),
                }}
                render={({ field }) => {
                  return (
                    <Textfield
                      id='newpass'
                      trim
                      type='password'
                      disabled={isLoading}
                      icon={<LockIcon />}
                      fullWidth
                      autoComplete='new-password'
                      size='lg'
                      placeholder={t(lng, 'app.populate.pass')}
                      showValidationEndIcons
                      error={errors?.password?.message}
                      {...field}
                    />
                  );
                }}
              />
            </div>
            <div className='change-password-textfield-wrapper'>
              <Controller
                name='passwordConfirm'
                control={control}
                rules={{
                  required: t(lng, 'app.password-is-required'),
                  validate: (value, formValues) =>
                    formValues?.password === value || t(lng, 'app.strings-is-not-match'),
                }}
                render={({ field }) => {
                  return (
                    <Textfield
                      id='repeatpass'
                      trim
                      type='password'
                      disabled={isLoading}
                      icon={<LockIcon />}
                      autoComplete='new-password'
                      fullWidth
                      size='lg'
                      placeholder={t(lng, 'app.confirm.pass')}
                      showValidationEndIcons
                      error={errors?.passwordConfirm?.message}
                      {...field}
                    />
                  );
                }}
              />
            </div>
            {isLoading ? (
              <div className='text-center mt-5'>
                <div className='spinner-border text-success' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <div className='d-grid mt-4'>
                <Button fullWidth disabled={!isValid}>
                  <i className='icon-submit-for-approval' />
                  <T>app.submit.for.approval</T>
                </Button>
              </div>
            )}
          </form>
        </VModal>
      )}
      {sussess && (
        <ActionModal toggleFunction={toggleFunction}>
          <T>app.change.password.descr</T>
        </ActionModal>
      )}
    </>
  );
};

export default ChangePassword;
