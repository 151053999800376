import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { T, t } from '../../T';
import { useSelector } from 'react-redux';
import RemoveWebhooksModal from '../../Modals/RemoveWebhooksModal';
import LoadMore from '../../LoadMore/LoadMore';
import { getWebhookEventsApi, getWebhooksApi } from '../../../api/endpoints';
import pendingIcon from '../../../assets/icons/statuses/status-pending.svg';
import signedIcon from '../../../assets/icons/statuses/status-signed.svg';
import Button from '../../Button/Button';
import WalletsTable from '../../GovernanceLayer/WalletsTable';
import VTooltip from '../../VTooltip/VTooltip';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTE_WEBHOOK } from '../../../routes/routes';
import CreateWebhookModal from '../../Modals/CreateWebhookModal/CreateWebhookModal';

const WebHooksTable = () => {
  const navigate = useNavigate();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [webhooksEvents, setWebHooksEvents] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCraeteModal, setOpenCreateModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [removeData, setRemoveData] = useState([]);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const webhooksCreatePermission = myProfile.premmisions?.includes('WEBHOOKS_CREATE');
  const webhooksDeletePermission = myProfile.premmisions?.includes('WEBHOOKS_DELETE');

  useEffect(() => {
    getPermissibleRoutes();
  }, []);

  useEffect(() => {
    if (typeUser) {
      getWebhooks();
    }
  }, [typeUser]);

  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (
        messageSocket?.data?.event === 'delete_webhook_approved' ||
        messageSocket?.data?.event === 'create_webhook_approved'
      ) {
        getWebhooks();
      }
    }
  }, [messageSocket]);
  const getPermissibleRoutes = () => {
    getWebhookEventsApi().then((res) => {
      setLoading(false);
      setWebHooksEvents(res.data.item);
    });
  };

  const getWebhooks = () => {
    getWebhooksApi({ params: { limit: 15 } })
      .then((res) => {
        const data = res?.data?.items.filter((i) => {
          return i?.wallets?.[0]?.type === typeUser.toLocaleUpperCase();
        });
        setApiList(data);
        setLoading(false);
        if (data.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        if (res?.data?.hasMore !== undefined && JSON.parse(res?.data?.hasMore) === true) {
          setLoadMoreVisible(true);
        }
      })
      .catch(() => setLoading(false));
  };
  // TODO: Need to see how backend status is comming
  const stautsShow = (stautsUser) => {
    if (stautsUser === 'PENDING_DELETE') {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={pendingIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.pending.delete</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'PENDING_APPROVAL') {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={pendingIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.pending.approval</T>
          </div>
        </div>
      );
    } else {
      return (
        <div className='status-bar'>
          <img className='status-icon' src={signedIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.active</T>
          </div>
        </div>
      );
    }
  };

  const loadMoreHandler = async () => {
    const lastRecord = apiList[apiList.length - 1];
    getWebhooksApi({ params: { startingAfter: lastRecord.id, limit: 15 } }).then((res) => {
      const newRecords = res.data.items.filter((i) => {
        return i.wallets[0].type === typeUser?.toLocaleUpperCase();
      });
      setApiList((prevState) => [...prevState, ...newRecords]);
      if (newRecords.length < 50) {
        setLoadMoreVisible(false);
      }
    });
  };

  return loading ? (
    <div className='team-page '>
      <div className='loading'>
        <ReactLoading type='spin' color='##020D1C' />
      </div>
    </div>
  ) : (
    <>
      <div className='api-table team-page webhooks'>
        <div className='titles'>
          <div className='titles-info'>
            <div className='title'>
              <T>app.hosted.endpoints</T>
            </div>
          </div>
          <div className='button-holder'>
            <Button
              onClick={() => setOpenCreateModal(true)}
              disabled={!webhooksCreatePermission}
              size='md'
              data-tooltip-id='add-endpoint-button'
              data-tooltip-content={t(lng, 'app.role.limitation')}
            >
              {!webhooksCreatePermission && <VTooltip id='add-endpoint-button' />}
              <i className='icon-add-contact' />
              <T>app.add.endpoint</T>
            </Button>
          </div>
        </div>

        <div className={`table webhooks-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
          <div className='table-row caption'>
            <div className='webhooks-table-data url'>
              <T>app.url</T>
            </div>
            <div className='webhooks-table-data wallets'>
              <T>app.wallets</T>
            </div>
            <div className='webhooks-table-data subscribed'>
              <T>app.events.subscribed</T>
            </div>
            <div className='webhooks-table-data status'>
              <T>app.status</T>
            </div>
            <div className='webhooks-table-data settings' />
          </div>
          {apiList.map((api, key) => {
            return (
              <div
                key={key}
                className={`table-row data ${api.state !== 'PENDING_APPROVAL' ? 'activeHover' : 'noHover'}`}
                onClick={() => {
                  if (api.state !== 'PENDING_APPROVAL') {
                    navigate(`${ROUTE_WEBHOOK}/${api.id}`, { state: api });
                  }
                }}
              >
                <div className='webhooks-table-data url'>
                  <span className='mobile-cell-title'>
                    <T>app.url</T>
                  </span>
                  <div className='url-text'>{api.url}</div>
                </div>
                <div className='webhooks-table-data wallets'>
                  <span className='mobile-cell-title'>
                    <T>app.wallets</T>
                  </span>
                  <div className='webhooks-wallets-flex'>
                    {api.wallets.map((wallet, key) => {
                      return <WalletsTable wallet={wallet} keyWallet={key} key={key} />;
                    })}
                    {api.wallets[3] && (
                      <div className='wallet-icon plus'>{`+ ${api.wallets.length - 3}`}</div>
                    )}
                  </div>
                </div>
                <div className='webhooks-table-data subscribed'>
                  <span className='mobile-cell-title'>
                    <T>app.events.subscribed</T>
                  </span>
                  {api.events.map((i) => webhooksEvents[i] + ' ').join()}
                </div>
                <div className='webhooks-table-data status'>
                  <span className='mobile-cell-title'>
                    <T>app.status</T>
                  </span>
                  {stautsShow(api.state)}
                </div>
                <div className='webhooks-table-data settings'>
                  <span className='mobile-cell-title'>
                    <T>app.settings</T>
                  </span>
                  {api.state === 'ACTIVE' && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        if (webhooksDeletePermission) {
                          setOpenRemoveModal(true);
                          setRemoveData(api);
                        }
                      }}
                      data-tooltip-id='webhooks-delete'
                      data-tooltip-content={t(lng, 'app.role.limitation')}
                    >
                      {!webhooksDeletePermission && <VTooltip id='webhooks-delete' />}
                      <div className='contact-row-settings-icon'>
                        <DeleteIcon className='contact-row-settings-icon-svg' />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {apiList.length === 0 && (
            <div className='table-row no-active'>
              <div>
                <T>app.no.webhooks</T>{' '}
              </div>
            </div>
          )}
          {loadMoreVisible && <LoadMore loadMoreHandler={loadMoreHandler} />}
        </div>
        {openCraeteModal && (
          <CreateWebhookModal successCallback={() => getWebhooks()} toggleFunction={setOpenCreateModal} />
        )}
        {openRemoveModal && (
          <RemoveWebhooksModal toggleFunction={() => setOpenRemoveModal(false)} data={removeData} />
        )}
      </div>
      {loadMoreVisible && <LoadMore loadMoreHandler={loadMoreHandler} />}
    </>
  );
};

export default WebHooksTable;
